import Layout from "./Layout"
import SmallCards from "./SmallCards"
import React from "react";
import Tippy from "@tippy.js/react"
import { tippyProps } from "./Header"
import { Link } from "gatsby"

export default function SmallCardPage({ data, showFooter, noContainer, large, showHeader, darkMode }) {
  const { frontmatter, html } = data.markdownRemark
  return (
    <Layout showCta={false} showFooter={false} noContainer={noContainer} darkMode={darkMode} page="home" showHeader={showHeader} title={frontmatter.title} subtitle={frontmatter.subtitle}>
      <SmallCards cards={frontmatter.card} large={large}/>
      <p className="hero-cta hero-copy hero-text">

      <Link className="button button-primary button-shadow mt-48"  data-scroll=""
            to={"organizations/orgs"}>
        Review All Organizations
      </Link>
      </p>
    </Layout>
  )
}
